import { LoaderFunctionArgs } from '@remix-run/node';
import { Outlet, useLocation } from '@remix-run/react';
import { Link } from 'react-router-dom';

import colorLogoSrc from '~/assets/logo-color.png';
import whiteLogoSrc from '~/assets/logo-white.png';
import { cn } from '~/lib/styles';

export async function loader({ context }: LoaderFunctionArgs) {
  await context.authService.requireAnonymous();
  return null;
}

export default function AuthLayout() {
  const location = useLocation();

  return (
    <div className="grid min-h-full">
      <header className="absolute left-0 right-0 top-0">
        <div className="container flex justify-center py-4 sm:block">
          <Link
            to="/"
            className={cn(
              'rounded-full px-4 py-2 text-lg font-extrabold text-brand-dark ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
              {
                'text-white': location.pathname === '/create-account'
              }
            )}
          >
            {location.pathname.startsWith('/create-account') ? (
              <img src={whiteLogoSrc} alt="Sublynk" className="h-5 w-auto" />
            ) : (
              <img src={colorLogoSrc} alt="Sublynk" className="h-5 w-auto" />
            )}
          </Link>
        </div>
      </header>

      <main>
        <Outlet />
      </main>
    </div>
  );
}
